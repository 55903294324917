<template>
  <v-container>
    <PageHeader :title="title" />
    <v-card class="m-0">
      <TextEditor ref="text" :value.sync="content" />
      <!-- <text-editor
        ref="text"
        :value.sync="content"
        :changed.sync="contentChanged"
      ></text-editor> -->
      <section class="d-flex justify-end">
        <ConfirmDialog
          :text="textConfirmDialog"
          :openConfirm.sync="openConfirmDialog"
          @onConfirm="confirmedCancel"
        />
        <v-btn outlined class="m-2" @click="confirmCancel">
          Cancelar
        </v-btn>
        <v-btn @click="enviarInfoPagos" class="m-2" color="primary">
          Guardar
        </v-btn>
      </section>
    </v-card>
  </v-container>
</template>

<script>
// import TextEditor from "@/components/textEditor/TextEditor.vue";
import TextEditor from "@/components/shared/TextEditor.vue";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import enums from "@/utils/enums/index.js";

export default {
  components: {
    TextEditor,
    PageHeader,
    ConfirmDialog
  },
  props: ["contentProp"],
  data() {
    return {
      content: "",
      contentChanged: false,
      title: "Información de medios de pagos",
      openConfirmDialog: false,
      textConfirmDialog: enums.titles.CONFIRMAR_REGRESO
    };
  },
  created() {
    this.loadParameters();
  },
  methods: {
    ...mapActions({
      getParameters: "configAppBenef/getParameters",
      postInfoMedioPago: "configAppBenef/postInfoMedioPago",
      setAlert: "user/setAlert"
    }),
    async loadParameters() {
      this.content = this.contentProp;
    },
    async enviarInfoPagos() {
      const data = {
        infoMediosDePago: this.content
      };
      const response = await this.postInfoMedioPago(data);
      if (response.status === 200)
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      this.$router.push({ name: "ParametrosGeneralesApp" });
    },
    confirmCancel() {
      this.openConfirmDialog = true;
    },
    confirmedCancel() {
      this.$router.push({ name: "ParametrosGeneralesApp" });
    }
  }
};
</script>

<style></style>
